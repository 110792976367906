// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
var angular = require('angular');
require('angular-moment');

import jquery from 'jquery';
window.jquery = jquery;
import 'bootstrap'
import "../stylesheets/application"

import "./application.css";
import "font-awesome/css/font-awesome.css";

document.addEventListener("turbolinks:load", () => {
    jquery('[data-toggle="tooltip"]').tooltip()
    jquery('[data-toggle="popover"]').popover()
    jquery(window).on('load', function () {
        jquery('#modal_initial').modal();
    });
})
console.log('Hello World from Webpacker22');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
